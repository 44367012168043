#Hero .content {
  text-align: center;
}

#Hero .memoji {
  max-height: 300px;
}

#Hero .arrow-down {
  max-height: 15px;
  cursor: pointer;
  -webkit-animation: floating 1s infinite alternate;
  -moz-animation: floating 1s infinite alternate;
  -o-animation: floating 1s infinite alternate;
  animation: floating 1s infinite alternate;
}

#Hero .to-about {
  display: none;
}

/* For smaller devices */
@media (max-width: 1150px) {
  #Hero {
    height: 90vh;
    margin-top: -110px;
  }

  #Hero .to-resume {
    display: none;
  }

  #Hero .to-about {
    display: block;
  }
}

/* For landscape and tablet devices */
@media (min-width: 480px) and (max-width: 1150px) {
  #Hero {
    height: auto;
    margin-top: 0px;
  }
}

@-webkit-keyframes floating {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

@-moz-keyframes floating {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

@-o-keyframes floating {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes floating {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
