header#Head {
  align-items: flex-start;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

#Head .logo {
  max-height: 60px;
  margin-bottom: 20px;
}

#Head .foot {
  display: flex;
  margin-top: 20px;
}

/* For smaller devices */
@media (max-width: 1150px) {
  #Head {
    position: static;
    justify-content: flex-end;
    height: 110px;
  }

  #Head .logo {
    max-height: 50px;
    margin: 0 auto;
  }

  #Head .content {
    display: none;
  }
}
