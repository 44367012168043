#Home {
  display: grid;
  width: 1150px;
  margin: 0 auto;
  grid-template-columns: 1fr 570px;
  gap: 100px;
}

#Home main #About {
  display: none;
}

/* For smaller devices */
@media (max-width: 1150px) {
  #Home {
    display: block;
    width: 100%;
    padding: 0 30px;
  }

  #Home main #About {
    display: block;
  }
}
