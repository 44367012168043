#SocialButtons {
  display: flex;
}

#SocialButtons a {
  position: relative;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  border-radius: 3px;
  text-align: center;
}

#SocialButtons a:hover {
  opacity: 0.6;
}

#SocialButtons img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
