#Navbar {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  padding: 0 30px 30px;
  display: none;
}

#Navbar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.9);
  width: 100%;
  padding: 5px;
  border-radius: 3px;
}

#Navbar .menu {
  background: rgba(0, 0, 0, 0.9);
  border-radius: 3px;
  padding: 5px 20px 18px 20px;
  margin-bottom: 5px;
}

#Navbar .menu-switcher {
  display: flex;
  align-items: center;
  padding-left: 15px;
}

#Navbar .arrow {
  max-height: 5px;
  margin-left: 5px;
}

#Navbar .open {
  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

/* For smaller devices */
@media (max-width: 1150px) {
  #Navbar {
    display: block;
  }
}
