#Contact {
  align-items: flex-start;
}

#Contact .head {
  margin-bottom: 30px;
}

#Contact .container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  width: 100%;
}

#Contact .content {
  background: var(--background-secondary-color);
  width: 50%;
  padding: 20px;
  border-radius: 3px;
  margin-bottom: 30px;
  flex: 1 0 30%;
}

#Contact .more {
  align-self: flex-end;
}

/* For smaller devices */
@media (max-width: 1150px) {
  #Contact .container {
    flex-direction: column;
  }

  #Contact .content {
    width: 100%;
  }
}
