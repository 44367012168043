@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  /* Color pallete */
  --grey-dark-color: #151515;
  --grey-light-color: #202022;

  --white-color: #ffffff;
  --grey-color: #949495;
  --yellow-color: #f2c94c;

  /* App theme */
  --background-color: var(--grey-dark-color);
  --background-secondary-color: var(--grey-light-color);
  --primary-color: var(--white-color);
  --secondary-color: var(--grey-color);
  --tertiary-color: var(--yellow-color);
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  background: var(--background-color);
  color: var(--primary-color);
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
}

input {
  all: unset;
}

::placeholder {
  opacity: 1;
}

section,
header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

a {
  color: var(--primary-color);
  transition: 300ms;
}

a:hover {
  opacity: 0.8;
}

h1,
h2,
h3 {
  font: 500 1em "Barlow", sans-serif;
  line-height: 1em;
}

h1 {
  font-size: 3em;
  margin-bottom: 10px;
}

h2 {
  font-size: 1.5em;
  margin-bottom: 5px;
}

h3 {
  font-size: 1.1em;
  margin-bottom: 5px;
}

p,
li {
  font: 400 1em "Inter", sans-serif;
  line-height: 1.6em;
  color: var(--secondary-color);
}

p.sub-header {
  font-size: 0.75em;
  text-transform: uppercase;
  color: var(--tertiary-color);
  line-height: normal;
  letter-spacing: 3px;
  margin-bottom: 5px;
}

p.secondary-text,
.secondary-text {
  font-size: 0.9em;
  line-height: normal;
  line-height: 1.5em;
}

mark {
  color: var(--primary-color);
  background: none;
}

input[type="text"] {
  width: 100%;
  height: 30px;
  font: 400 12px "Inter", sans-serif;
  color: var(--secondary-color);
  letter-spacing: 3px;
  text-transform: uppercase;
  text-decoration: none;
  border-bottom: 1px solid var(--secondary-color);
}

button {
  border: none;
  border-radius: 3px;
  padding: 0 12px;
  height: 30px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 3px;
  cursor: pointer;
}

button.btn-primary {
  background: var(--tertiary-color);
  color: var(--grey-dark-color);
}

button.btn-secondary {
  background: var(--background-secondary-color);
  color: var(--secondary-color);
}

button:hover {
  opacity: 0.8;
}

.button {
  display: block;
  font: 400 12px "Inter", sans-serif;
  color: var(--secondary-color);
  letter-spacing: 3px;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  transition: 300ms;
}

.button hr {
  display: inline-block;
  width: 30px;
  border: 0;
  height: 0;
  border-top: 1px solid var(--secondary-color);
  margin-right: 10px;
  vertical-align: middle;
  transition: 300ms;
}

.button:hover {
  color: var(--primary-color);
}

.button:hover hr {
  border-color: var(--primary-color);
  width: 60px;
}

/* For smaller devices */
@media (max-width: 1150px) {
  section {
    height: auto;
    padding: 30px 0;
  }
  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.2em;
  }

  h3 {
    font-size: 1em;
  }

  p {
    font-size: 1em;
  }
}
