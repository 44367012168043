@import url(https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
#Home {
  display: grid;
  width: 1150px;
  margin: 0 auto;
  grid-template-columns: 1fr 570px;
  grid-gap: 100px;
  gap: 100px;
}

#Home main #About {
  display: none;
}

/* For smaller devices */
@media (max-width: 1150px) {
  #Home {
    display: block;
    width: 100%;
    padding: 0 30px;
  }

  #Home main #About {
    display: block;
  }
}

header#Head {
  align-items: flex-start;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

#Head .logo {
  max-height: 60px;
  margin-bottom: 20px;
}

#Head .foot {
  display: flex;
  margin-top: 20px;
}

/* For smaller devices */
@media (max-width: 1150px) {
  #Head {
    position: static;
    justify-content: flex-end;
    height: 110px;
  }

  #Head .logo {
    max-height: 50px;
    margin: 0 auto;
  }

  #Head .content {
    display: none;
  }
}

#About .social {
  display: none;
  margin-top: 15px;
}

/* For smaller devices */
@media (max-width: 1150px) {
  #About {
    padding: 30px 0;
  }

  #About .social {
    display: block;
  }
}

#SocialButtons {
  display: flex;
}

#SocialButtons a {
  position: relative;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  border-radius: 3px;
  text-align: center;
}

#SocialButtons a:hover {
  opacity: 0.6;
}

#SocialButtons img {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

#Menu nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#Menu li a {
  display: block;
  font: 400 12px 'Inter', sans-serif;
  color: var(--secondary-color);
  letter-spacing: 3px;
  margin-top: 15px;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  transition: 300ms;
}

#Menu li a hr {
  display: inline-block;
  width: 30px;
  border: 0;
  height: 0;
  border-top: 1px solid var(--secondary-color);
  margin-right: 10px;
  vertical-align: middle;
  transition: 300ms;
}

#Menu nav ul li:hover a,
#Menu nav ul li .active {
  color: var(--primary-color);
}

#Menu nav ul li:hover hr,
#Menu nav ul li .active hr {
  border-color: var(--primary-color);
  width: 60px;
}

/* For smaller devices */
@media (max-width: 1150px) {
  /* #Menu nav ul li a {
    display: none;
  }
  #Menu nav ul .active {
    display: block;
  } */
}

#LangSwitcher button {
  width: 70px;
  height: 31px;
  padding: 0;
}

#Hero .content {
  text-align: center;
}

#Hero .memoji {
  max-height: 300px;
}

#Hero .arrow-down {
  max-height: 15px;
  cursor: pointer;
  -webkit-animation: floating 1s infinite alternate;
  animation: floating 1s infinite alternate;
}

#Hero .to-about {
  display: none;
}

/* For smaller devices */
@media (max-width: 1150px) {
  #Hero {
    height: 90vh;
    margin-top: -110px;
  }

  #Hero .to-resume {
    display: none;
  }

  #Hero .to-about {
    display: block;
  }
}

/* For landscape and tablet devices */
@media (min-width: 480px) and (max-width: 1150px) {
  #Hero {
    height: auto;
    margin-top: 0px;
  }
}

@-webkit-keyframes floating {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}

@keyframes floating {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}

#Resume .container {
  display: flex;
  align-items: stretch;
  background: var(--background-secondary-color);
  width: 100%;
  border-radius: 3px;
  margin-bottom: 30px;
}

#Resume .image {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  width: 150px;
  border-radius: 3px;
}

#Resume .content {
  padding: 30px;
}

#Resume .content h2 {
  font-weight: 600;
}

#Resume .secondary {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  gap: 30px;
}

#Resume .secondary .content {
  padding: 20px;
}

#Resume .button {
  align-self: flex-end;
}

/* For smaller devices */
@media (max-width: 1150px) {
  #Resume .container {
    display: block;
  }

  #Resume .image {
    width: 100%;
    padding: 20px;
  }

  #Resume .image img {
    max-width: 55px;
  }

  #Resume .content {
    padding: 20px;
  }

  #Resume .secondary {
    display: block;
    width: 100%;
  }
}

#Skills .container {
  position: relative;
  background: var(--background-secondary-color);
  width: 100%;
  border-radius: 3px;
  margin-bottom: 15px;
  z-index: -1;
}

#Skills .content {
  padding: 20px;
}

#Skills .bar {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: black;
  width: 0px;
  height: 100%;
  border-radius: 3px;
  transition: width 2s ease-out;
  z-index: -2;
}

#Skills .other {
  line-height: 1.3em;
}

#Portfolio .slider {
  position: relative;
  width: 100%;
  padding-bottom: 15px;
  border-radius: 3px;
  overflow: hidden;
}

#Portfolio .content {
  position: relative;
  display: flex;
  transition: left 500ms ease-in-out;
}

#Portfolio .slide {
  min-width: 80%;
  margin-right: 30px;
}

#Portfolio .mockup {
  margin-bottom: 15px;
}

#Portfolio .mockup img.desktop {
  max-width: 350px;
  border-radius: 3px;
}

#Portfolio .mockup img.mobile {
  max-width: 80px;
  border-radius: 3px;
  margin-left: -60px;
}

#Portfolio .slide h2 {
  font-weight: 600;
}

#Portfolio .skills {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  margin-bottom: 10px;
}

#Portfolio .skill {
  background: black;
  font: 500 1em "Barlow", sans-serif;
  color: var(--secondary-color);
  margin-right: 5px;
  padding: 5px 10px;
  border-radius: 3px;
}

#Portfolio .slide .button {
  margin-top: 15px;
}

#Portfolio .next {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(21, 21, 21, 0.6) 50%,
    rgba(21, 21, 21, 1) 100%
  );
  width: 20%;
  height: 100%;
  cursor: pointer;
  justify-content: flex-end;
  align-items: center;
}

#Portfolio .next img {
  max-height: 25px;
  opacity: 0.4;
  -webkit-animation: next 1s infinite alternate;
  animation: next 1s infinite alternate;
}

#Portfolio .more {
  align-self: flex-end;
}

#Portfolio .foot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
}

#Portfolio .foot p {
  font: 400 10px "Inter", sans-serif;
  color: var(--secondary-color);
  letter-spacing: 3px;
  text-transform: uppercase;
  text-decoration: none;
}

/* For smaller devices */
@media (max-width: 1150px) {
  #Portfolio .slide {
    min-width: 100%;
  }

  #Portfolio .mockup img.desktop {
    max-width: 80%;
  }

  #Portfolio .mockup img.mobile {
    max-width: 60px;
    margin-left: -40px;
  }

  #Portfolio .skill {
    font-size: 12px;
  }

  #Portfolio .next {
    background: none;
  }

  #Portfolio .foot {
    flex-direction: column;
    align-items: flex-end;
  }

  #Portfolio .foot p {
    margin-bottom: 10px;
  }
}

@-webkit-keyframes next {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
}

@keyframes next {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
}

#Articles .container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  gap: 30px;
  width: 100%;
}

#Articles .post {
  background-position: center;
  background-size: cover;
  border-radius: 3px;
}

#Articles .content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 50%,
    rgba(0, 0, 0, 0.9) 100%
  );
  height: 250px;
  padding: 20px;
  border-radius: 3px;
}

#Articles a {
  text-decoration: none;
}

#Articles .foot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
}

#Articles .foot p {
  font: 400 10px 'Inter', sans-serif;
  color: var(--secondary-color);
  letter-spacing: 3px;
  text-transform: uppercase;
  text-decoration: none;
}

/* For smaller devices */
@media (max-width: 1150px) {
  #Articles .container {
    display: block;
  }

  #Articles .foot {
    flex-direction: column;
    align-items: flex-end;
  }

  #Articles .foot p {
    margin-bottom: 10px;
  }
}

#Contact {
  align-items: flex-start;
}

#Contact .head {
  margin-bottom: 30px;
}

#Contact .container {
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
  width: 100%;
}

#Contact .content {
  background: var(--background-secondary-color);
  width: 50%;
  padding: 20px;
  border-radius: 3px;
  margin-bottom: 30px;
  flex: 1 0 30%;
}

#Contact .more {
  align-self: flex-end;
}

/* For smaller devices */
@media (max-width: 1150px) {
  #Contact .container {
    flex-direction: column;
  }

  #Contact .content {
    width: 100%;
  }
}

#Foot {
  text-align: right;
  margin-bottom: 60px;
}

#Foot p {
  margin-bottom: 10px;
  line-height: 1.3em;
}

#Foot .links a {
  display: block;
  font: 400 10px 'Inter', sans-serif;
  line-height: 1.8em;
  color: var(--secondary-color);
  letter-spacing: 3px;
  text-transform: uppercase;
  text-decoration: none;
}

#Navbar {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  padding: 0 30px 30px;
  display: none;
}

#Navbar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.9);
  width: 100%;
  padding: 5px;
  border-radius: 3px;
}

#Navbar .menu {
  background: rgba(0, 0, 0, 0.9);
  border-radius: 3px;
  padding: 5px 20px 18px 20px;
  margin-bottom: 5px;
}

#Navbar .menu-switcher {
  display: flex;
  align-items: center;
  padding-left: 15px;
}

#Navbar .arrow {
  max-height: 5px;
  margin-left: 5px;
}

#Navbar .open {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

/* For smaller devices */
@media (max-width: 1150px) {
  #Navbar {
    display: block;
  }
}

#Projects .container {
  -webkit-columns: 2;
          columns: 2;
  grid-gap: 30px;
  gap: 30px;
}

#Projects .project {
  display: inline-block;
  width: 100%;
  border-radius: 3px;
  margin-bottom: 30px;
}

#Projects .content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: var(--background-secondary-color);
  padding: 20px;
  border-radius: 3px;
  transition: left 500ms ease-in-out;
}

#Projects a {
  text-decoration: none;
}

#Projects .topics {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}

#Projects .topic {
  background: var(--background-color);
  font: 400 13px "Barlow", sans-serif;
  color: var(--secondary-color);
  margin: 5px 5px 0 0;
  padding: 4px 10px;
  border-radius: 3px;
}

#Projects .pagination {
  width: 100%;
}

#Projects .pagination ul {
  display: flex;
  list-style-type: none;
  float: right;
  grid-gap: 5px;
  gap: 5px;
}

#Projects .pagination ul li {
  position: relative;
  width: 30px;
  height: 30px;
  color: var(--secondary-color);
  text-align: center;
  line-height: 30px;
  font-size: 12px;
  cursor: pointer;
}

#Projects .pagination ul li.active {
  color: var(--primary-color);
  background: var(--background-secondary-color);
  border-radius: 3px;
}

#Projects ul li:hover {
  opacity: 0.8;
}

#Projects .foot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
}

#Projects .foot p {
  font: 400 10px "Inter", sans-serif;
  color: var(--secondary-color);
  letter-spacing: 3px;
  text-transform: uppercase;
  text-decoration: none;
}

/* For smaller devices */
@media (max-width: 1150px) {
  #Projects .container {
    -webkit-columns: unset;
            columns: unset;
  }

  #Projects .foot {
    flex-direction: column;
    align-items: flex-end;
  }

  #Projects .foot p {
    margin-bottom: 10px;
  }
}

#Job {
  display: grid;
  width: 1150px;
  margin: 0 auto;
  grid-template-columns: 1fr 570px;
  grid-gap: 100px;
  gap: 100px;
}

#Job aside {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

#Job main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

#Job h2 {
  margin-bottom: 10px;
}

#Job p {
  margin-bottom: 5px;
}

#Job strong {
  color: var(--primary-color);
}

#Job img.picture {
  border-radius: 3px;
}

#Job .speech {
  position: relative;
  background: var(--primary-color);
  color: var(--grey-dark-color);
  border-radius: 3px 3px 3px 0px;
  margin-bottom: 10px;
}

#Job .speech::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: var(--primary-color);
  border-bottom: 0;
  border-left: 0;
  margin-bottom: -10px;
}

#Job .speech p {
  font: 400 12px "Inter", sans-serif;
  color: var(--grey-light-color);
  padding: 10px;
  margin: 0;
}

@media (min-width: 1150px) {
  #Job main #Navigator {
    display: none;
  }
}

@media (max-width: 1150px) {
  #Job {
    display: block;
    width: 100%;
    padding: 60px 30px;
  }

  #Job aside,
  #Job main {
    height: auto;
  }

  #Job aside {
    margin-bottom: 30px;
  }

  #Job aside #Navigator {
    display: none;
  }

  #Job section {
    padding: 0;
  }
}

#Presentation .logo {
  max-height: 60px;
  margin-bottom: 20px;
}

#Presentation b.different {
  -webkit-animation: background-pan 1.5s linear infinite;
          animation: background-pan 1.5s linear infinite;
  background: linear-gradient(to right, #f2c94c, rgb(255, 235, 176), #f2c94c);
  background-size: 200%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;
}

@-webkit-keyframes background-pan {
  from {
    background-position: 0 center;
  }
  to {
    background-position: -200% center;
  }
}

@keyframes background-pan {
  from {
    background-position: 0 center;
  }
  to {
    background-position: -200% center;
  }
}

#Applications .head {
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 30px;
  grid-gap: 30px;
  gap: 30px;
}

#Applications .head img {
  display: block;
  max-width: 80px;
  border-radius: 3px;
}

#Applications .container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  gap: 30px;
  width: 100%;
}

#Applications .application {
  width: 100%;
  border-radius: 3px;
}

#Applications .content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: var(--background-secondary-color);
  padding: 20px;
  border-radius: 3px;
  transition: left 500ms ease-in-out;
}

#Applications a {
  text-decoration: none;
}

#Applications .stack {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}

#Applications .stack .item {
  background: var(--background-color);
  font: 400 13px "Barlow", sans-serif;
  color: var(--secondary-color);
  margin: 5px 5px 0 0;
  padding: 4px 10px;
  border-radius: 3px;
}

@media (max-width: 1150px) {
  #Applications .container {
    display: flex;
    flex-direction: column;
  }
}

#Navigator {
  display: flex;
  margin-top: 10px;
  grid-gap: 10px;
  gap: 10px;
}

#Navigator input {
  opacity: 0.8;
}

@media (max-width: 1150px) {
  #Navigator {
    justify-content: space-between;
    margin-top: 30px;
  }

  #Navigator input {
    margin-bottom: 5px;
  }

  #Navigator.presentation {
    flex-direction: column;
  }
}

#AboutCards .speech {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 30px;
}

#AboutCards .container {
  display: grid;
  grid-gap: 30px;
  gap: 30px;
  margin-bottom: 30px;
}

#AboutCards .container.primary {
  grid-template-columns: 150px 1fr;
}

#AboutCards .container.secondary {
  grid-template-columns: 1fr 150px;
}

#AboutCards .picture img {
  display: block;
  width: 100%;
  border-radius: 3px;
}

#AboutCards .picture .speech {
  display: none;
}

#AboutCards .behance {
  display: flex;
  justify-content: center;
  background: #1d6fed;
  border-radius: 3px;
  height: 150px;
}

#AboutCards .behance img {
  width: 50px;
}

#AboutCards .card {
  display: flex;
  align-items: center;
  background: var(--background-secondary-color);
  width: 100%;
  border-radius: 3px;
}

#AboutCards .card .content {
  padding: 0 30px;
}

#AboutCards .card .content h2 {
  font-weight: 600;
  font-size: 20px;
}

#AboutCards .card .stack {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}

#AboutCards .card .stack .item {
  background: var(--background-color);
  font: 400 13px "Barlow", sans-serif;
  color: var(--secondary-color);
  margin: 5px 5px 0 0;
  padding: 4px 10px;
  border-radius: 3px;
}

#AboutCards .button {
  float: right;
}

@media (max-width: 1150px) {
  #AboutCards .speech {
    display: none;
    margin: 0;
  }

  #AboutCards .container {
    display: block;
    margin: 0;
  }

  #AboutCards .picture {
    display: flex;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 30px;
    grid-gap: 30px;
    gap: 30px;
  }

  #AboutCards .picture img {
    max-width: 80px;
  }

  #AboutCards .picture .speech {
    display: block;
    margin-bottom: 10px;
  }

  #AboutCards .card {
    margin-bottom: 30px;
  }

  #AboutCards .card .content {
    padding: 30px;
  }

  #AboutCards .behance {
    display: none;
  }
}

#Skills .speech {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 30px;
}

#Skills .container {
  position: relative;
  background: var(--background-secondary-color);
  width: 100%;
  border-radius: 3px;
  margin-bottom: 15px;
  z-index: -1;
}

#Skills .content {
  padding: 20px;
}

#Skills .bar {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: black;
  width: 0px;
  height: 100%;
  border-radius: 3px;
  transition: width 2s ease-out;
  z-index: -2;
}

#Skills .button {
  float: right;
  margin-top: 15px;
}

#Finals p strong {
  font-weight: normal;
}

#Finals #SocialButtons {
  margin-top: 10px;
}

:root {
  /* Color pallete */
  --grey-dark-color: #151515;
  --grey-light-color: #202022;

  --white-color: #ffffff;
  --grey-color: #949495;
  --yellow-color: #f2c94c;

  /* App theme */
  --background-color: var(--grey-dark-color);
  --background-secondary-color: var(--grey-light-color);
  --primary-color: var(--white-color);
  --secondary-color: var(--grey-color);
  --tertiary-color: var(--yellow-color);
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  background: #151515;
  background: var(--background-color);
  color: #ffffff;
  color: var(--primary-color);
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
}

input {
  all: unset;
}

::-webkit-input-placeholder {
  opacity: 1;
}

:-ms-input-placeholder {
  opacity: 1;
}

::placeholder {
  opacity: 1;
}

section,
header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

a {
  color: #ffffff;
  color: var(--primary-color);
  transition: 300ms;
}

a:hover {
  opacity: 0.8;
}

h1,
h2,
h3 {
  font: 500 1em "Barlow", sans-serif;
  line-height: 1em;
}

h1 {
  font-size: 3em;
  margin-bottom: 10px;
}

h2 {
  font-size: 1.5em;
  margin-bottom: 5px;
}

h3 {
  font-size: 1.1em;
  margin-bottom: 5px;
}

p,
li {
  font: 400 1em "Inter", sans-serif;
  line-height: 1.6em;
  color: #949495;
  color: var(--secondary-color);
}

p.sub-header {
  font-size: 0.75em;
  text-transform: uppercase;
  color: #f2c94c;
  color: var(--tertiary-color);
  line-height: normal;
  letter-spacing: 3px;
  margin-bottom: 5px;
}

p.secondary-text,
.secondary-text {
  font-size: 0.9em;
  line-height: normal;
  line-height: 1.5em;
}

mark {
  color: #ffffff;
  color: var(--primary-color);
  background: none;
}

input[type="text"] {
  width: 100%;
  height: 30px;
  font: 400 12px "Inter", sans-serif;
  color: #949495;
  color: var(--secondary-color);
  letter-spacing: 3px;
  text-transform: uppercase;
  text-decoration: none;
  border-bottom: 1px solid #949495;
  border-bottom: 1px solid var(--secondary-color);
}

button {
  border: none;
  border-radius: 3px;
  padding: 0 12px;
  height: 30px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 3px;
  cursor: pointer;
}

button.btn-primary {
  background: #f2c94c;
  background: var(--tertiary-color);
  color: #151515;
  color: var(--grey-dark-color);
}

button.btn-secondary {
  background: #202022;
  background: var(--background-secondary-color);
  color: #949495;
  color: var(--secondary-color);
}

button:hover {
  opacity: 0.8;
}

.button {
  display: block;
  font: 400 12px "Inter", sans-serif;
  color: #949495;
  color: var(--secondary-color);
  letter-spacing: 3px;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  transition: 300ms;
}

.button hr {
  display: inline-block;
  width: 30px;
  border: 0;
  height: 0;
  border-top: 1px solid #949495;
  border-top: 1px solid var(--secondary-color);
  margin-right: 10px;
  vertical-align: middle;
  transition: 300ms;
}

.button:hover {
  color: #ffffff;
  color: var(--primary-color);
}

.button:hover hr {
  border-color: #ffffff;
  border-color: var(--primary-color);
  width: 60px;
}

/* For smaller devices */
@media (max-width: 1150px) {
  section {
    height: auto;
    padding: 30px 0;
  }
  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.2em;
  }

  h3 {
    font-size: 1em;
  }

  p {
    font-size: 1em;
  }
}

