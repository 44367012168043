#Resume .container {
  display: flex;
  align-items: stretch;
  background: var(--background-secondary-color);
  width: 100%;
  border-radius: 3px;
  margin-bottom: 30px;
}

#Resume .image {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  width: 150px;
  border-radius: 3px;
}

#Resume .content {
  padding: 30px;
}

#Resume .content h2 {
  font-weight: 600;
}

#Resume .secondary {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

#Resume .secondary .content {
  padding: 20px;
}

#Resume .button {
  align-self: flex-end;
}

/* For smaller devices */
@media (max-width: 1150px) {
  #Resume .container {
    display: block;
  }

  #Resume .image {
    width: 100%;
    padding: 20px;
  }

  #Resume .image img {
    max-width: 55px;
  }

  #Resume .content {
    padding: 20px;
  }

  #Resume .secondary {
    display: block;
    width: 100%;
  }
}
