#Navigator {
  display: flex;
  margin-top: 10px;
  gap: 10px;
}

#Navigator input {
  opacity: 0.8;
}

@media (max-width: 1150px) {
  #Navigator {
    justify-content: space-between;
    margin-top: 30px;
  }

  #Navigator input {
    margin-bottom: 5px;
  }

  #Navigator.presentation {
    flex-direction: column;
  }
}
