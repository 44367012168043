#Portfolio .slider {
  position: relative;
  width: 100%;
  padding-bottom: 15px;
  border-radius: 3px;
  overflow: hidden;
}

#Portfolio .content {
  position: relative;
  display: flex;
  transition: left 500ms ease-in-out;
}

#Portfolio .slide {
  min-width: 80%;
  margin-right: 30px;
}

#Portfolio .mockup {
  margin-bottom: 15px;
}

#Portfolio .mockup img.desktop {
  max-width: 350px;
  border-radius: 3px;
}

#Portfolio .mockup img.mobile {
  max-width: 80px;
  border-radius: 3px;
  margin-left: -60px;
}

#Portfolio .slide h2 {
  font-weight: 600;
}

#Portfolio .skills {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  margin-bottom: 10px;
}

#Portfolio .skill {
  background: black;
  font: 500 1em "Barlow", sans-serif;
  color: var(--secondary-color);
  margin-right: 5px;
  padding: 5px 10px;
  border-radius: 3px;
}

#Portfolio .slide .button {
  margin-top: 15px;
}

#Portfolio .next {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(21, 21, 21, 0.6) 50%,
    rgba(21, 21, 21, 1) 100%
  );
  width: 20%;
  height: 100%;
  cursor: pointer;
  justify-content: flex-end;
  align-items: center;
}

#Portfolio .next img {
  max-height: 25px;
  opacity: 0.4;
  -webkit-animation: next 1s infinite alternate;
  -moz-animation: next 1s infinite alternate;
  -o-animation: next 1s infinite alternate;
  animation: next 1s infinite alternate;
}

#Portfolio .more {
  align-self: flex-end;
}

#Portfolio .foot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
}

#Portfolio .foot p {
  font: 400 10px "Inter", sans-serif;
  color: var(--secondary-color);
  letter-spacing: 3px;
  text-transform: uppercase;
  text-decoration: none;
}

/* For smaller devices */
@media (max-width: 1150px) {
  #Portfolio .slide {
    min-width: 100%;
  }

  #Portfolio .mockup img.desktop {
    max-width: 80%;
  }

  #Portfolio .mockup img.mobile {
    max-width: 60px;
    margin-left: -40px;
  }

  #Portfolio .skill {
    font-size: 12px;
  }

  #Portfolio .next {
    background: none;
  }

  #Portfolio .foot {
    flex-direction: column;
    align-items: flex-end;
  }

  #Portfolio .foot p {
    margin-bottom: 10px;
  }
}

@-webkit-keyframes next {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-10px);
  }
}

@-moz-keyframes next {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-10px);
  }
}

@-o-keyframes next {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-10px);
  }
}

@keyframes next {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-10px);
  }
}
