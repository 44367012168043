#Applications .head {
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 30px;
  gap: 30px;
}

#Applications .head img {
  display: block;
  max-width: 80px;
  border-radius: 3px;
}

#Applications .container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  width: 100%;
}

#Applications .application {
  width: 100%;
  border-radius: 3px;
}

#Applications .content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: var(--background-secondary-color);
  padding: 20px;
  border-radius: 3px;
  transition: left 500ms ease-in-out;
}

#Applications a {
  text-decoration: none;
}

#Applications .stack {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}

#Applications .stack .item {
  background: var(--background-color);
  font: 400 13px "Barlow", sans-serif;
  color: var(--secondary-color);
  margin: 5px 5px 0 0;
  padding: 4px 10px;
  border-radius: 3px;
}

@media (max-width: 1150px) {
  #Applications .container {
    display: flex;
    flex-direction: column;
  }
}
