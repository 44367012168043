#About .social {
  display: none;
  margin-top: 15px;
}

/* For smaller devices */
@media (max-width: 1150px) {
  #About {
    padding: 30px 0;
  }

  #About .social {
    display: block;
  }
}
