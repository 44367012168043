#Articles .container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  width: 100%;
}

#Articles .post {
  background-position: center;
  background-size: cover;
  border-radius: 3px;
}

#Articles .content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 50%,
    rgba(0, 0, 0, 0.9) 100%
  );
  height: 250px;
  padding: 20px;
  border-radius: 3px;
}

#Articles a {
  text-decoration: none;
}

#Articles .foot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
}

#Articles .foot p {
  font: 400 10px 'Inter', sans-serif;
  color: var(--secondary-color);
  letter-spacing: 3px;
  text-transform: uppercase;
  text-decoration: none;
}

/* For smaller devices */
@media (max-width: 1150px) {
  #Articles .container {
    display: block;
  }

  #Articles .foot {
    flex-direction: column;
    align-items: flex-end;
  }

  #Articles .foot p {
    margin-bottom: 10px;
  }
}
