#AboutCards .speech {
  width: fit-content;
  margin-bottom: 30px;
}

#AboutCards .container {
  display: grid;
  gap: 30px;
  margin-bottom: 30px;
}

#AboutCards .container.primary {
  grid-template-columns: 150px 1fr;
}

#AboutCards .container.secondary {
  grid-template-columns: 1fr 150px;
}

#AboutCards .picture img {
  display: block;
  width: 100%;
  border-radius: 3px;
}

#AboutCards .picture .speech {
  display: none;
}

#AboutCards .behance {
  display: flex;
  justify-content: center;
  background: #1d6fed;
  border-radius: 3px;
  height: 150px;
}

#AboutCards .behance img {
  width: 50px;
}

#AboutCards .card {
  display: flex;
  align-items: center;
  background: var(--background-secondary-color);
  width: 100%;
  border-radius: 3px;
}

#AboutCards .card .content {
  padding: 0 30px;
}

#AboutCards .card .content h2 {
  font-weight: 600;
  font-size: 20px;
}

#AboutCards .card .stack {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}

#AboutCards .card .stack .item {
  background: var(--background-color);
  font: 400 13px "Barlow", sans-serif;
  color: var(--secondary-color);
  margin: 5px 5px 0 0;
  padding: 4px 10px;
  border-radius: 3px;
}

#AboutCards .button {
  float: right;
}

@media (max-width: 1150px) {
  #AboutCards .speech {
    display: none;
    margin: 0;
  }

  #AboutCards .container {
    display: block;
    margin: 0;
  }

  #AboutCards .picture {
    display: flex;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 30px;
    gap: 30px;
  }

  #AboutCards .picture img {
    max-width: 80px;
  }

  #AboutCards .picture .speech {
    display: block;
    margin-bottom: 10px;
  }

  #AboutCards .card {
    margin-bottom: 30px;
  }

  #AboutCards .card .content {
    padding: 30px;
  }

  #AboutCards .behance {
    display: none;
  }
}
